import React from "react";
import styled from "styled-components/macro";
import { Button } from "./Button";
import { servicesData } from "../data/ServicesData";

const Services = () => {
  return (
    <ProductsContainer id="services">
      <ProductsHeading>Naše služby</ProductsHeading>
      <ProductWrapper>
        {servicesData.map((item, index) => (
          <ProductCard key={index}>
            <ProductImg alt={item.title} src={item.img} />
            <ProductInfo>
              <TextWrap>
                <ProductTitle>{item.title}</ProductTitle>
              </TextWrap>
              <Button
                primary="true"
                round="true"
                to={item.link}
                css={`
                  position: absolute;
                  top: 420px;
                  font-size: 14px;
                `}
              >
                {item.button}
              </Button>
            </ProductInfo>
          </ProductCard>
        ))}
      </ProductWrapper>
    </ProductsContainer>
  );
};

export default Services;

const ProductsContainer = styled.div`
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  color: #fff;
`;

const ProductsHeading = styled.div`
  font-size: clamp(1.2rem, 5vw, 3rem);
  text-align: center;
  margin-bottom: 5rem;
  color: #000;
`;
const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  justify-items: center;
  padding: 0 2rem;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ProductCard = styled.div`
  line-height: 2;
  width: 100%;
  height: 500px;
  position: relative;
  border-radius: 10px;
  transition: 0.2 ease;
`;
const ProductImg = styled.img`
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 10px;
  filter: brightness(100%);
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    filter: brightness(30%);
  }
`;
const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 2rem;

  @media screen and (max-width: 280px) {
    padding: 0 1rem;
  }
`;

const TextWrap = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 375px;
`;
const ProductTitle = styled.div`
  font-weight: 400;
  font-size: 1rem;
  margin-left: 0.5rem;
`;
