import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import emailjs from "emailjs-com";
import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

const schema = yup.object().shape({
  name: yup.string().required("Toto pole je povinné !"),
  email: yup
    .string()
    .email("Neplatná e-mailová adresa!")
    .required("Toto pole je povinné !"),
  subject: yup.string().required("Toto pole je povinné !"),
  message: yup.string().required("Toto pole je povinné !"),
});

function Contact() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const [disabled, setDisabled] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const [result, setResult] = useState(null);

  const sendEmail = (e) => {
    emailjs
      .send("gmail", "template_fvq0jjv", e, "user_FTGqaDNTbGCd3Hl51jxTK")
      .then(
        (result) => {
          console.log(result.text);
          setResult(result.text);
        },
        (error) => {
          console.log(error.text);
          setResult(error.text);
        }
      );
    reset();
    setDisabled(true);
  };

  return (
    <>
      <ContactContainer>
        <FormsContainer>
          <ContactForm>
            <FormH1>Společnost</FormH1>
            <address>
              <strong>Bendystav s.r.o.</strong>
              <p>Robertova 846</p>
              <p>667 01 Židlochovice</p>
              <a href="mailto:info@bendystav.cz">info@bendystav.cz</a>
              <p>Jozef Benčík</p>
              <p>602 520 122</p>
              <a href="mailto:bencik@bendystav.cz">bencik@bendystav.cz</a>
            </address>
          </ContactForm>
          <FormContainer className="contact" onSubmit={handleSubmit(sendEmail)}>
            <FormH1>Kontaktní formulář</FormH1>
            <TextField
              name="name"
              className="outlined-required"
              label="Jméno a příjmení"
              placeholder="Jméno a příjmení"
              variant="outlined"
              inputRef={register}
              style={{
                width: "100%",
              }}
            />
            <FormP className="error">{errors.name?.message}</FormP>
            <TextField
              name="email"
              className="outlined-required"
              label="Email"
              placeholder="Email"
              variant="outlined"
              inputRef={register}
              style={{
                width: "100%",
              }}
            />
            <FormP className="error">{errors.email?.message}</FormP>
            <TextField
              name="subject"
              className="outlined-required"
              label="Předmět"
              placeholder="Předmět"
              variant="outlined"
              inputRef={register}
              style={{
                width: "100%",
              }}
            />
            <FormP className="error">{errors.subject?.message}</FormP>
            <TextField
              name="message"
              multiline
              rowsMax={4}
              className="outlined-required"
              label="Zpráva"
              placeholder="Napište zprávu"
              variant="outlined"
              inputRef={register}
              style={{
                width: "100%",
              }}
            />
            <FormP className="error">{errors.message?.message}</FormP>
            <FormButton
              disabled={disabled}
              lightBg="true"
              big="true"
              type="submit"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
            >
              Poslat zprávu {hover ? <ArrowForward /> : <ArrowRight />}
            </FormButton>
            {result && (
              <FormSendMessage>
                Odeslali jste zprávu. Děkujeme za kontakt.
              </FormSendMessage>
            )}
          </FormContainer>
        </FormsContainer>
      </ContactContainer>
    </>
  );
}

export default Contact;

export const ContactContainer = styled.div`
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  background-color: #f9f9f9;
`;
export const FormsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  justify-items: center;
  padding: 0 2rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
export const ContactForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const FormH1 = styled.h1`
  font-size: clamp(1.2rem, 5vw, 3rem);
  text-align: center;
  margin-bottom: 5rem;
  color: #000;
  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

export const FormP = styled.p`
  margin-top: 10px;
  margin-bottom: 5px;
  color: #fa0501;
`;

export const FormButton = styled.button`
  border-radius: 50px;
  background: ${({ lightBg }) => (lightBg ? "#fa0501" : "#010001")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ lightBg }) => (lightBg ? "#010001" : "#fa0501")};
  }
  &:disabled {
    background: #dddddd;
  }
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const FormSendMessage = styled.p`
  color: green;
  margin-top: 20px;
`;
