import React from "react";
import styled from "styled-components";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { projectsData } from "../data/ProjectsData";

const ProjectFour = () => {
  return (
    <ProjectFourContainer>
      <ProjectFourTitle>{projectsData[3].title}</ProjectFourTitle>
      <ProjectFourDesc>{projectsData[3].desc}</ProjectFourDesc>
      <ProjectFourImage items={projectsData[3].img} />
    </ProjectFourContainer>
  );
};

export default ProjectFour;

const ProjectFourContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
`;
const ProjectFourTitle = styled.h1`
  font-size: clamp(1.2rem, 5vw, 3rem);
  text-align: center;
  margin-bottom: 5rem;
`;
const ProjectFourDesc = styled.p`
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 300;
  padding: 0 2rem;
  padding-bottom: 2rem;
`;
const ProjectFourImage = styled(ImageGallery)``;
