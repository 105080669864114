import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages";
import RenovationsPage from "./pages/renovations";
import BuildingPage from "./pages/building";
import InsulationPage from "./pages/insulation";
import CarpenterPage from "./pages/carpenter";
import ContactPage from "./pages/contact";
import ProjectsPage from "./pages/projects";
import ProjectOnePage from "./pages/project1";
import ProjectTwoPage from "./pages/project2";
import ProjectThreePage from "./pages/project3";
import ProjectFourPage from "./pages/project4";
import ProjectFivePage from "./pages/project5";
import ProjectSixPage from "./pages/project6";
import ProjectSevenPage from "./pages/project7";
import ProjectEightPage from "./pages/project8";
import ProjectNinePage from "./pages/project9";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/renovations" component={RenovationsPage} />
        <Route exact path="/building" component={BuildingPage} />
        <Route exact path="/insulation" component={InsulationPage} />
        <Route exact path="/carpenter" component={CarpenterPage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/projects" component={ProjectsPage} />
        <Route exact path="/project1" component={ProjectOnePage} />
        <Route exact path="/project2" component={ProjectTwoPage} />
        <Route exact path="/project3" component={ProjectThreePage} />
        <Route exact path="/project4" component={ProjectFourPage} />
        <Route exact path="/project5" component={ProjectFivePage} />
        <Route exact path="/project6" component={ProjectSixPage} />
        <Route exact path="/project7" component={ProjectSevenPage} />
        <Route exact path="/project8" component={ProjectEightPage} />
        <Route exact path="/project9" component={ProjectNinePage} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
