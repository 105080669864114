import Img_1 from "../assets/images/bathroom1.jpg";
import Img_1_1 from "../assets/images/bathroom.jpg";
import Img_1_2 from "../assets/images/bathroom2.jpg";
import Img_2 from "../assets/images/bed.jpg";
import Img_3 from "../assets/images/fasad.jpg";
import Img_3_1 from "../assets/images/fasad1.jpg";
import Img_3_2 from "../assets/images/fasad2.jpg";
import Img_3_3 from "../assets/images/fasad3.jpg";
import Img_4 from "../assets/images/fasad_pribici4.jpg";
import Img_4_1 from "../assets/images/fasad_pribici3.jpg";
import Img_4_2 from "../assets/images/fasad_pribici2.jpg";
import Img_4_3 from "../assets/images/fasad_pribici1.jpg";
import Img_4_4 from "../assets/images/fasad_pribici.jpg";
import Img_5 from "../assets/images/wardrobe.jpg";
import Img_5_1 from "../assets/images/wardrobe1.jpg";
import Img_6 from "../assets/images/kohut.jpg";
import Img_6_1 from "../assets/images/kohut-1.jpg";
import Img_6_2 from "../assets/images/kohut-2.jpg";
import Img_6_3 from "../assets/images/kohut-3.jpg";
import Img_6_4 from "../assets/images/kohut-4.jpg";
import Img_6_5 from "../assets/images/dom.jpg";
import Img_7 from "../assets/images/bed_massive.jpg";
import Img_7_1 from "../assets/images/bed_massive1.jpg";
import Img_7_2 from "../assets/images/bed_massive2.jpg";
import Img_8 from "../assets/images/speaker2.jpg";
import Img_8_1 from "../assets/images/speaker1.jpg";
import Img_8_2 from "../assets/images/speaker.jpg";
import Img_9 from "../assets/images/barak.jpeg";
import Img_9_3 from "../assets/images/barak-3.jpeg";
import Img_9_4 from "../assets/images/barak-4.jpeg";
import Img_9_6 from "../assets/images/barak-6.jpeg";
import Img_9_7 from "../assets/images/barak-7.jpeg";
import Img_9_8 from "../assets/images/barak-8.jpeg";
import Img_9_9 from "../assets/images/barak-9.jpeg";
import Img_9_10 from "../assets/images/barak-10.jpeg";
import Img_9_11 from "../assets/images/barak-11.jpeg";

export const projectsData = [
  {
    id: 6,
    category: "Výstavba",
    title: "Stavba rodinného domu pro majitele klempířské firmy pana Kohúta",
    desc: "Během stavby bylo využité systémové řešení od společnosti Ytong, více informací sí můžete přečít zde.",
    img: [
      {
        original: Img_6_4,
        thumbnail: Img_6_4,
      },
      {
        original: Img_6_5,
        thumbnail: Img_6_5,
      },
      {
        original: Img_6_1,
        thumbnail: Img_6_1,
      },
      {
        original: Img_6_2,
        thumbnail: Img_6_2,
      },
      {
        original: Img_6_3,
        thumbnail: Img_6_3,
      },
      {
        original: Img_6,
        thumbnail: Img_6,
      },
    ],
    link: "/project6",
  },
  {
    id: 2,
    category: "Výroba nábytku",
    title: "Výroba postele na míru",
    desc: "Po dvou negativních zkušenostech s koupí postele z obchodního domu nás oslovil zákazník, jehož přání bylo konečně spát a číst si na posteli, která nevrže, neskřípe a vydrží více déle než záruční lhůtu.Navrhli jsme proto zvýšenou postel z lamina s pevným roštem, vysokou matrací a s vyšším čelem pro pohodlné večerní (nejen) čtení.",
    img: [
      {
        original: Img_2,
        thumbnail: Img_2,
      },
    ],
    link: "/project2",
  },
  {
    id: 3,
    category: "Rekonstrukce",
    title: "Zateplení fasády bytového domu",
    desc: "Požadavek zákazníka byla oprava špatného stavu venkovní omítky domu a příjezdové cesty s parkovacími plochami.Při přípravě této zakázky jsme zákazníkem dohodli v rámci opravy venkovních omítek provést i zateplení celé fasády a opravu vstupního schodiště.Příjezdovou cestu a parkovací stání jsme navrhli rozšířit a provést ze zámkové dlažby místo asfaltového recyklátu, který původně požadoval zákazník. Zámkovou dlažbu jsme vybrali kvůli bezprašnému provedení a snadnější údržbě.",
    img: [
      {
        original: Img_3,
        thumbnail: Img_3,
      },
      {
        original: Img_3_1,
        thumbnail: Img_3_1,
      },
      {
        original: Img_3_2,
        thumbnail: Img_3_2,
      },
      {
        original: Img_3_3,
        thumbnail: Img_3_3,
      },
    ],
    link: "/project3",
  },
  {
    id: 4,
    category: "Rekonstrukce",
    title: "Realizace fasády na novostavbě rodinného domu v Přibicích",
    desc: "Zákazník nás oslovil kvůli dokončení fasády rodinného domu. Při prohlídce domu a připravenosti fasády na finální vrstvu probarvené omítky bylo zjištěno špatné provedení podkladní vrstvy pro fasádu. Nejdříve jsme udělali celou podkladní vrstvu (zátěr se skelnou tkaninou, včetně vyztužení kritických míst a rohů) a potom teprve nanesli finální probarvenou omítku.",
    img: [
      {
        original: Img_4_3,
        thumbnail: Img_4_3,
      },
      {
        original: Img_4_2,
        thumbnail: Img_4_2,
      },
      {
        original: Img_4_4,
        thumbnail: Img_4_4,
      },
      {
        original: Img_4,
        thumbnail: Img_4,
      },
      {
        original: Img_4_1,
        thumbnail: Img_4_1,
      },
    ],
    link: "/project4",
  },
  {
    id: 5,
    category: "Výroba nábytku",
    title: "Výroba vestavěných skříní",
    desc: "Zákazník byl značně limitován prostorem domu, a proto potřeboval vytvořit úložné prostory v předsíni.Pro zákazníka jsme navrhli vestavěnou skříň umístěnou ve volném prostoru nad pračkou, a přitom jsme i pračku nechali ukrytou v prostoru skříně aby nenarušovala vzhled prostoru předsíně.",
    img: [
      {
        original: Img_5,
        thumbnail: Img_5,
      },
      {
        original: Img_5_1,
        thumbnail: Img_5_1,
      },
    ],
    link: "/project5",
  },

  {
    id: 7,
    category: "Výroba nábytku",
    title: "Výroba masivní postele na míru",
    desc: "Pro zákazníka jsme vyrobili prodlouženou manželskou postel z masivu, která vydrží i ty nejdivočejší noci.",
    img: [
      {
        original: Img_7,
        thumbnail: Img_7,
      },
      {
        original: Img_7_1,
        thumbnail: Img_7_1,
      },
      {
        original: Img_7_2,
        thumbnail: Img_7_2,
      },
    ],
    link: "/project7",
  },
  {
    id: 1,
    category: "Rekonstrukce",
    title: "Obložení nově budované koupelny atypických tvarů",
    desc: "Obložení koupelny bylo provedeno velkoformátovým obkladem, který zvýraznil netradiční tvar místnosti. Na podlahu byla použita dlažba s imitací dřeva pro hřejivý pocit teplé podlahy, sprchová vanička se sprchovým koutem byly nainstalovány přímo na podlahu pro pohodlné užívání.",
    img: [
      {
        original: Img_1_1,
        thumbnail: Img_1_1,
      },
      {
        original: Img_1_2,
        thumbnail: Img_1_2,
      },
      {
        original: Img_1,
        thumbnail: Img_1,
      },
    ],
    link: "/project1",
  },
  {
    id: 8,
    category: "Výroba nábytku",
    title: "Výroba masivních beden na reproduktory",
    desc: "Zasazením do beden z masivního dřeva jsme z klasické sestavy reproduktorů udělali designovku do obýváků. Kromě designu se díky materiálu beden zvýšila kvalita zvuku o 20 %.",
    img: [
      {
        original: Img_8,
        thumbnail: Img_8,
      },
      {
        original: Img_8_1,
        thumbnail: Img_8_1,
      },
      {
        original: Img_8_2,
        thumbnail: Img_8_2,
      },
    ],
    link: "/project8",
  },
  {
    id: 9,
    category: "Výstavba",
    title: "Stavba rodinného domu ve Velaticích",
    desc: "",
    img: [
      {
        original: Img_9,
        thumbnail: Img_9,
      },
      {
        original: Img_9_4,
        thumbnail: Img_9_4,
      },
      {
        original: Img_9_3,
        thumbnail: Img_9_3,
      },
      {
        original: Img_9_6,
        thumbnail: Img_9_6,
      },
      {
        original: Img_9_7,
        thumbnail: Img_9_7,
      },
      {
        original: Img_9_8,
        thumbnail: Img_9_8,
      },
      {
        original: Img_9_9,
        thumbnail: Img_9_9,
      },
      {
        original: Img_9_11,
        thumbnail: Img_9_11,
      },
      {
        original: Img_9_10,
        thumbnail: Img_9_10,
      },
    ],
    link: "/project9",
  },
];
