import React, { useState } from "react";
import Header from "../components/Header";
import Building from "../components/Building";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Slider from "../components/Slider";

function BuildingPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} hideLinkS />
      <Header toggle={toggle} hideLinkS />
      <Building />
      <Slider category="Výstavba" title="Naše stavební projekty"/>
      <Footer hideLinkS />
    </>
  );
}

export default BuildingPage;
