import React from "react";
import styled from "styled-components";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { projectsData } from "../data/ProjectsData";

const ProjectTwo = () => {
  return (
    <ProjectTwoContainer>
      <ProjectTwoTitle>{projectsData[1].title}</ProjectTwoTitle>
      <ProjectTwoDesc>{projectsData[1].desc}</ProjectTwoDesc>
      <ProjectTwoImage items={projectsData[1].img} />
    </ProjectTwoContainer>
  );
};

export default ProjectTwo;

const ProjectTwoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
`;
const ProjectTwoTitle = styled.h1`
  font-size: clamp(1.2rem, 5vw, 3rem);
  text-align: center;
  margin-bottom: 5rem;
`;
const ProjectTwoDesc = styled.p`
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 300;
  padding: 0 2rem;
  padding-bottom: 2rem;
`;
const ProjectTwoImage = styled(ImageGallery)``;
