import React from "react";
import styled from "styled-components/macro";
import { StatsData } from "../data/StatsData";

const Stats = () => {
  return (
    <StatsContainer>
      <Heading>Proč nás vybrat?</Heading>
      <Wrapper>
        {StatsData.map((item, index) => (
          <StatsBox key={index}>
            <Icon>{item.icon}</Icon>
            <Title>{item.title}</Title>
            <Description>{item.desc}</Description>
          </StatsBox>
        ))}
      </Wrapper>
    </StatsContainer>
  );
};

export default Stats;

const StatsContainer = styled.div`
  width: 100%;
  background: #f9f9f9;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 4rem calc((100vw - 1300px) / 2);
`;
const Heading = styled.h1`
  font-size: clamp(1.2rem, 5vw, 3rem);
  text-align: center;
  margin-bottom: 5rem;
  color: #000;
`;
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
const StatsBox = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem;
`;
const Icon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
`;
const Title = styled.p`
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  margin-bottom: 0.5rem;
  font-weight: bold;
`;
const Description = styled.p``;
