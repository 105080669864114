import React from "react";
import { GrDiamond, GrTools } from "react-icons/gr";
import { MdTimer } from "react-icons/md";
import { RiPencilRulerLine } from "react-icons/ri";

export const StatsData = [
  {
    icon: <GrDiamond />,
    title: "Česká firma",
    desc: "Kvalita za dobré peníze",
  },
  {
    icon: <MdTimer />,
    title: "Profesionální práce",
    desc: "Více než 30 let zkušeností v oboru",
  },
  {
    icon: <GrTools />,
    title: "Inovativnost",
    desc: "Nejmodernější řešení na trhu",
  },
  {
    icon: <RiPencilRulerLine />,
    title: "Na míru",
    desc: "Jak si vysníte, tak my zrealizujeme",
  },
];
