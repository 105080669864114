import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { projectsData } from "../data/ProjectsData";

import { Link } from "react-router-dom";

import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

function Slider({ category, title }) {
  let num = +window.location.href.slice(-1);
  let filteredProjects;
  if (num === isNaN) {
    filteredProjects = projectsData.filter(function (project) {
      return project.category === category;
    });
  } else {
    filteredProjects = projectsData.filter(function (project) {
      return project.category === category && project.id !== num;
    });
  }

  const [projects, setProjects] = useState(filteredProjects);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = projects.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, projects]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 5000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);

  return (
    <>
      {projects.length > 0 ? (
        <SliderSection>
          <SliderTitle>
            <h2>{title || "Další projekty"}</h2>
          </SliderTitle>
          <SliderSectionCenter>
            {projects.map((project, projectIndex) => {
              const { id, img, title, link } = project;
              let position = "nextSlide";
              if (projectIndex === index) {
                position = "activeSlide";
              } else if (
                projectIndex === index - 1 ||
                (index === 0 && projectIndex === projects.length - 1)
              ) {
                position = "lastSlide";
              }

              return (
                <SliderArticle
                  className={position}
                  position={position}
                  key={id}
                >
                  <LinkTitle to={link}>
                    <ProjectImg src={img[0].thumbnail} alt={title} />
                  </LinkTitle>
                  <h4>
                    <LinkTitle to={link}>{title}</LinkTitle>
                  </h4>
                </SliderArticle>
              );
            })}
            <PrevButton onClick={() => setIndex(index - 1)}>
              <FiChevronLeft />
            </PrevButton>
            <NextButton onClick={() => setIndex(index + 1)}>
              <FiChevronRight />
            </NextButton>
          </SliderSectionCenter>
        </SliderSection>
      ) : (
        <></>
      )}
    </>
  );
}

export default Slider;

const SliderSection = styled.section`
  width: 90vw;
  margin: 5rem auto;
`;
const SliderTitle = styled.div`
  display: flex;
  justify-content: center;
`;
const SliderSectionCenter = styled.section`
  margin: 0 auto;
  margin-top: 4rem;
  width: 80vw;
  height: 450px;
  max-width: 800px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
`;

const SliderArticle = styled.article`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.5s ease-in-out;
`;

const PrevButton = styled.button`
  position: absolute;
  top: 150px;
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  cursor: pointer;
  left: 0;
  &:hover {
    background-color: red;
  }
`;

const NextButton = styled.button`
  position: absolute;
  top: 150px;
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  cursor: pointer;
  right: 0;
`;

const ProjectImg = styled.img`
  margin-bottom: 1rem;
  width: 250px;
  height: 250px;
  object-fit: cover;
`;

const LinkTitle = styled(Link)`
  text-decoration: none;
  color: black;
`;
