import React from "react";
import styled from "styled-components/macro";
import Logo from "../assets/images/logo2.png";
import { animateScroll as scroll } from "react-scroll";
import { Link as LinkS } from "react-scroll";
import { Link as LinkR } from "react-router-dom";

const Footer = ({ hideLinkS }) => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <FooterContainer>
        <FooterWrapper>
          <FooterDesc>
            <FooterLink onClick={toggleHome}>
              <FooterLogo src={Logo} alt="Logo" />
            </FooterLink>
            <p>
              Jsme profesionální stavební firma s působností po celém
              Jihomoravském kraji. Specializujeme se na rekonstrukce od A do Z,
              zateplování a stavbu rodinných domů na klíč.
            </p>
          </FooterDesc>
        </FooterWrapper>
        <FooterWrapper>
          <FooterWrapperDesc>
            <FooterTitle>Kontakt</FooterTitle>
            <FooterAddress>
                <p>Robertova 846</p>
                <p>667 01 Židlochovice</p>
                <a href="mailto:info@bendystav.cz">info@bendystav.cz</a>
                <p>Jozef Benčík</p>
                <a href="tel:602-520-122">Mob.: 602 520 122</a>
            </FooterAddress>
          </FooterWrapperDesc>
          <FooterWrapperDesc>
            <FooterTitle>Navigace</FooterTitle>
            <FooterLinks>
              {hideLinkS ? null : (
                <>
                  <NavLinkS
                    smooth={true}
                    spy={true}
                    exact="true"
                    to="aboutus"
                    white
                  >
                    O nás
                  </NavLinkS>
                  <NavLinkS
                    smooth={true}
                    spy={true}
                    exact="true"
                    to="services"
                    white
                  >
                    Služby
                  </NavLinkS>
                </>
              )}

              {hideLinkS ? <NavLinkR to="/">Home</NavLinkR> : null}
              <NavLinkR to="/contact">Kontakt</NavLinkR>
              <NavLinkR to="/projects">Projekty</NavLinkR>
            </FooterLinks>
          </FooterWrapperDesc>
        </FooterWrapper>
      </FooterContainer>
      <FooterAuthor>
        Copyright &copy; Michal Godun. All rights reserved.
      </FooterAuthor>
    </>
  );
};

export default Footer;

const FooterContainer = styled.div`
  padding: 5rem calc((100vw - 1300px) / 2);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: #000;
  justify-content: flex-start;
  background-color: #f9f9f9;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;

  }
`;
const FooterDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;

  p {
    text-align: left;
    font-size: 1rem;
    line-height: 1.5;
  }
`;
const FooterLink = styled.div`
  cursor: pointer;
`;
const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 1rem 2rem;
  justify-content: flex-end;

  @media screen and (max-width: 768px){
    justify-content: flex-start;
  }
`;
const FooterWrapperDesc = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem;
  padding-right: 2rem;
`;
const FooterTitle = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 16px;
`;
const FooterAddress = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: 1.5;
  align-items: flex-start;
`;

const FooterLogo = styled.img`
  height: 6rem;
`;
const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: 1.5;
  align-items: flex-start;
`;

const NavLinkS = styled(LinkS)`
  color: #000;
  display: flex;
  text-decoration: none;
  height: 100%;
  cursor: pointer;

  &:hover {
    color: #fa0501;
    transition: 0.3s ease-in-out;
  }
`;
const NavLinkR = styled(LinkR)`
  color: #000;
  display: flex;
  text-decoration: none;
  height: 100%;
  cursor: pointer;

  &:hover {
    color: #fa0501;
    transition: 0.3s ease-in-out;
  }
`;

const FooterAuthor = styled.div`
  display: flex;
  align-items: center;
  justify-content: fle;
  background-color: #f1f1f1;
`;
