import React from "react";
import styled from "styled-components/macro";
import { FaBars } from "react-icons/fa";
import { Button } from "./Button";
import Logo from "../assets/images/logo.png";
import { Link as LinkS } from "react-scroll";
import { Link as LinkR } from "react-router-dom";

const Header = ({ toggle, hideLinkS, white }) => {
  return (
    <Nav>
      <NavLinkR to="/">
        <NavLinkImg src={Logo} alt="Logo" />
      </NavLinkR>

      {white ? (
        <>
          <Bars onClick={toggle} white />
          <NavMenu>
            {hideLinkS ? null : (
              <>
                <NavLinkS
                  smooth={true}
                  spy={true}
                  exact="true"
                  to="aboutus"
                  white
                >
                  O nás
                </NavLinkS>
                <NavLinkS
                  smooth={true}
                  spy={true}
                  exact="true"
                  to="services"
                  white
                >
                  Služby
                </NavLinkS>
              </>
            )}
            <NavLinkR to="/projects" white>
              Projekty
            </NavLinkR>
          </NavMenu>
        </>
      ) : (
        <>
          <Bars onClick={toggle} />
          <NavMenu>
            {hideLinkS ? null : (
              <>
                <NavLinkS smooth={true} spy={true} exact="true" to="aboutus">
                  O nás
                </NavLinkS>
                <NavLinkS smooth={true} spy={true} exact="true" to="services">
                  Služby
                </NavLinkS>
              </>
            )}
            <NavLinkR to="/">Home</NavLinkR>
            <NavLinkR to="/projects">Projekty</NavLinkR>
          </NavMenu>
        </>
      )}

      <NavBtn>
        <Button primary="true" round="true" to="/contact">
          Kontaktujte nás
        </Button>
      </NavBtn>
    </Nav>
  );
};

export default Header;

const Nav = styled.nav`
  background: transparent;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem calc((100vw - 1300px) / 2);
  z-index: 100;
  position: relative;
`;
const NavLinkS = styled(LinkS)`
  color: ${({ white }) => (white ? "#fff" : "#000")};
  font-weight: bold;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &:hover {
    color: #fa0501;
    transition: 0.3s ease-in-out;
  }
`;
const NavLinkR = styled(LinkR)`
  color: ${({ white }) => (white ? "#fff" : "#000")};
  font-weight: bold;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &:hover {
    color: #fa0501;
    transition: 0.3s ease-in-out;
  }
`;
const Bars = styled(FaBars)`
  display: none;
  color: ${({ white }) => (white ? "#fff" : "#000")};

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    cursor: pointer;
    font-size: 1.8rem;
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavLinkImg = styled.img`
  height: 100%;
`;
