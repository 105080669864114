import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Projects from "../components/Projects";
import Sidebar from "../components/Sidebar";

function ProjectsPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} hideLinkS />
      <Header toggle={toggle} hideLinkS />
      <Projects />
      <Footer hideLinkS />
    </>
  );
}

export default ProjectsPage;
