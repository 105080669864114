import React from "react";
import styled from "styled-components/macro";
import BuildImg1 from "../assets/images/dom.jpg";
function Building() {
  return (
    <BuildingContainer>
      <BuildingImage>
        <BuildingTitleBg>
          <BuildingTitle>Výstavba bytů a domů</BuildingTitle>
        </BuildingTitleBg>
      </BuildingImage>
      <BuildingDescription>
        <BuildingText>
          <p>
            Těšíte se na nový domov a nemůžete se dočkat jeho dokončení? S námi
            se ho dočkáte v co nejkratší době a nejvyšší kvalitě.
            <strong>
              Provádíme stavby na klíč – od prvotního návrhu, zaměření objektu a
              vyhotovení rozpočtu až po konečnou realizaci včetně zednických a
              obkladačských prací, instalací vody, elektřiny či plynu
            </strong>
            . Postavíme Vám celý dům nebo jen základovou desku či hrubou stavbu.
            Jednoduše to, co budete potřebovat .
          </p>
          <br />
          <p>
            Při stavbě domu, bytu či rekonstrukci budeme Vašimi partnery, ne
            jenom dělníky . Záleží nám na vzájemné komunikaci.
            <strong>
              Vy zakomponujete všechna svoje přání a představy do stavební
              dokumentace a my dle tohoto zadání budeme realizovat danou stavbu{" "}
            </strong>{" "}
            . Vyhneme se tak nečekaným více-pracím a z toho plynoucímu navýšení
            ceny. Na případných změnách se dohodnete s pracovníkem, který je Vám
            k dispozici od začátku projektu.
          </p>
        </BuildingText>
      </BuildingDescription>
    </BuildingContainer>
  );
}

export default Building;

export const BuildingContainer = styled.div`
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
`;

export const BuildingTitle = styled.h1`
  font-size: clamp(1.2rem, 5vw, 3rem);
  text-align: center;
  color: #000;
`;

export const BuildingTitleBg = styled.div`
  width: auto;
  padding: 8px 20px;
  background:rgba(255,255,255, 0.5);
`;

export const BuildingDescription = styled.div`
margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  height: 100%;
  padding: 0 2rem;
`;

export const BuildingImage = styled.div`
  background-image: url(${BuildImg1});
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BuildingText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 300;
  line-height: 1.5;
  padding: 1rem 0;
  @media screen and (max-width: 1020px) {
    margin-left: 0;
    margin-top: 20px;
  }
`;
