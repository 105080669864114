import React, { useState } from "react";
import styled from "styled-components/macro";
import { projectsData } from "../data/ProjectsData";

import { Link } from "react-router-dom";
const categories = ["Výstavba", "Rekonstrukce", "Výroba nábytku"];

const Projects = () => {
  const [projects, setProjects] = useState(projectsData);
  const [filteredProjects, setFilteredProjects] = useState(projectsData);
  const [wasFiltered, setWasFiltered] = useState(false);

  const filterProjects = (category) => {
    console.log(category);
    let temp = projects.filter(function (projects) {
      return projects.category === category;
    });
    setFilteredProjects(temp);
    setWasFiltered(true);
  };
  const resetFilters = () => {
    setFilteredProjects(projects);
    setWasFiltered(false);
  };

  return (
    <ProductsContainer>
      <ProductsHeading>Naše projekty</ProductsHeading>
      <FilterContainer>
        Filtry:
        {categories.map((item, index) => {
          return (
            <FilterButton
              key={index}
              isActive={
                projects.length !== filteredProjects.length &&
                filteredProjects[0]?.category === item
              }
              onClick={(e) => filterProjects(e.target.innerText)}
            >
              {item}
            </FilterButton>
          );
        })}
        <FilterButton onClick={resetFilters}>Vyčistit filtr</FilterButton>
      </FilterContainer>

      {wasFiltered ? (
        <ProductWrapper>
          {filteredProjects.map((item, index) => (
            <ProductCard key={index}>
              <ImageLink to={item.link}>
                <ProductImage src={item.img[0].original} />
              </ImageLink>
              <ProductInfo>
                <TextWrap>
                  <ProductTitle>{item.title}</ProductTitle>
                  <ProductDesc>{item.desc.substring(0, 120)}...</ProductDesc>
                </TextWrap>
              </ProductInfo>
              <TextLink to={item.link}>Více informací »</TextLink>
            </ProductCard>
          ))}
        </ProductWrapper>
      ) : (
        <ProductWrapper>
          {projects.map((item, index) => (
            <ProductCard key={index}>
              <ImageLink to={item.link}>
                <ProductImage src={item.img[0].original} />
              </ImageLink>
              <ProductInfo>
                <TextWrap>
                  <ProductTitle>{item.title}</ProductTitle>
                  <ProductDesc>{item.desc.substring(0, 120)}...</ProductDesc>
                </TextWrap>
              </ProductInfo>
              <TextLink to={item.link}>Více informací »</TextLink>
            </ProductCard>
          ))}
        </ProductWrapper>
      )}
    </ProductsContainer>
  );
};

export default Projects;
const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const FilterButton = styled.div`
  padding: 10px 20px;
  margin: 10px 10px;
  border-radius: 10px;
  border: 1px solid black;
  width: fit-content;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? "#fa0501" : "white")};
  color: ${(props) => (props.isActive ? "white" : "black")};
`;
const ProductsContainer = styled.div`
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  color: #000;
`;

const ProductsHeading = styled.div`
  font-size: clamp(1.2rem, 5vw, 3rem);
  text-align: center;
  margin-bottom: 5rem;
  color: #000;
`;
const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 1rem 2rem;
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ProductCard = styled.div`
  margin: 1rem 1rem;
  height: 100%;
  width: auto;
  box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  transition: 0.4s ease-in-out;
  &:hover {
  }
`;

const ProductImage = styled.img`
  height: 250px;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  filter: brightness(100%);
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    filter: brightness(70%);
    cursor: pointer;
  }
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 2rem;

  @media screen and (max-width: 280px) {
    padding: 0 1rem;
  }
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const ProductTitle = styled.h1`
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;
const ProductDesc = styled.span`
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;
const TextLink = styled(Link)`
  color: #fa0501;
  text-decoration: none;
  margin-left: 2rem;
  font-size: 13px;
`;
const ImageLink = styled(Link)``;
