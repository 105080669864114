import React from "react";
import styled from "styled-components/macro";
import CarpenterImg1 from "../assets/images/carpenter-2.jpg";
function Carpenter() {
  return (
    <CarpenterContainer>
      <CarpenterImage>
        <CarpenterTitleBg>
          <CarpenterTitle>Výroba nábytku na míru</CarpenterTitle>
          <CarpenterSubtitle>
            Není to jen práce, ale především naše hobby
          </CarpenterSubtitle>
        </CarpenterTitleBg>
      </CarpenterImage>
      <CarpenterDescription>
        <CarpenterText>
          <p>
            Už máte dost rozvrzaného nábytku z obchodních řetěžců nebo prostě
            jen chcete originální kousek do vašeho domu? Na zakázku vám
            zhotovíme výrobek z prvotřídníh materiálu, který vydrží desítky let.
          </p>
          <br />
          <p>Co vše Vám vyrobíme?</p>
          <br />
          <ul>
            <li>✓ Kuchyňské linky</li>
            <li>✓ Šatny</li>
            <li>✓ Koupelny</li>
            <li>✓ Postele</li>
            <li>✓ Vestavěné skříně</li>
            <li>✓ Dětské pokoje </li>
            <li>…a mnoho dalšího</li>
          </ul>
          <br />
          <p>Konzultace ZDARMA! Napiště nám.</p>
        </CarpenterText>
      </CarpenterDescription>
    </CarpenterContainer>
  );
}

export default Carpenter;

export const CarpenterContainer = styled.div`
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
`;

export const CarpenterTitle = styled.h1`
  font-size: clamp(1.2rem, 5vw, 3rem);
  text-align: center;
  margin-bottom: 1rem;
  color: #000;
`;
export const CarpenterSubtitle = styled.h2`
  text-align: center;
  font-style: italic;
  font-weight: 300;
`;
export const CarpenterTitleBg = styled.div`
  width: auto;
  padding: 8px 20px;
  background: rgba(255, 255, 255, 0.5);
`;

export const CarpenterDescription = styled.div`
  margin-top: 2rem; 
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  height: 100%;
  padding: 0 2rem;
`;

export const CarpenterImage = styled.div`
  background-image: url(${CarpenterImg1});
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CarpenterText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 300;
  line-height: 1.5;
  padding: 1rem 0;
  @media screen and (max-width: 1020px) {
    margin-left: 0;
    margin-top: 20px;
  }
  li {
    list-style-type: none;
  }
`;
