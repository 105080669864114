import Img_1 from "../assets/images/renovation-2.jpg";
import Img_2 from "../assets/images/construction-1.jpg";
import Img_3 from "../assets/images/insulation.jpg";
import Img_4 from "../assets/images/carpenter-1.jpg";

export const servicesData = [
  {
    title: "Rekonstrukce bytů a domů",
    img: Img_1,
    button: "Zobrazit službu",
    link: "/renovations",
  },
  {
    title: "Výstavba bytů a domů",
    img: Img_2,
    button: "Zobrazit službu",
    link: "/building",
  },
  {
    title: "Zateplení budov, střech a fasád",
    img: Img_3,
    button: "Zobrazit službu",
    link: "/insulation",
  },
  {
    title: "Výroba nábytku na míru",
    img: Img_4,
    button: "Zobrazit službu",
    link: "/carpenter",
  },
];
