import React from "react";
import styled from "styled-components/macro";
import { Link as LinkS } from "react-scroll";
import { Link as LinkR } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

const Sidebar = ({ isOpen, toggle, hideLinkS }) => {
  return (
    <SidebarContainer isOpen={isOpen} toggle={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
        <SidebarLinkR onClick={toggle} to="/">
            Home
          </SidebarLinkR>
          {hideLinkS ? null : (
            <>
              <SidebarLinkS
                onClick={toggle}
                smooth={true}
                spy={true}
                exact="true"
                to="aboutus"
              >
                O nás
              </SidebarLinkS>
              <SidebarLinkS
                onClick={toggle}
                smooth={true}
                spy={true}
                exact="true"
                to="services"
              >
                Služby
              </SidebarLinkS>
            </>
          )}
          <SidebarLinkR onClick={toggle} to="/projects">
            Projekty
          </SidebarLinkR>
          <SidebarLinkR onClick={toggle} to="/contact">
            Kontakt
          </SidebarLinkR>

        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;

export const CloseIcon = styled(FaTimes)`
  color: #fff;
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;
export const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;

  @media screen and (max-width: 400px) {
    grid-template-rows: repeat(6, 80px);
  }
`;

export const SidebarWrapper = styled.div`
  color: #fff;
`;

export const SidebarLinkR = styled(LinkR)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #fa0501;
    transition: 0.3s ease-in-out;
  }
`;

export const SidebarLinkS = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #fa0501;
    transition: 0.3s ease-in-out;
  }
`;
