import React from "react";
import InsImg from "../assets/images/insulation-2.jpg";
import styled from "styled-components/macro";
function Insulation() {
  return (
    <InsulationContainer>
      <InsulationImage>
      <InsulationTitleBg>
        <InsulationTitle>Zateplení budov, střech a fasád</InsulationTitle>
      </InsulationTitleBg>
      </InsulationImage>
      <InsulationDescription>
        <InsulationText>
          <p>
            Inovujte s námi vaše bydlení k lepšímu. Orientujeme se na zateplení
            budov, rodinných a panelových domů a stavby na klíč. Náklady na
            vytápění, plyn a energie nás všechny mohou minimálně jednou ročně
            poněkud zaskočit. Pomocí efektivního zateplení vašeho domova ale
            ušetříte významnou část původních nákladů a zajistíte, aby částky za
            energie zůstaly na minimální hodnotě. Zateplujeme fasády i střechy s
            nejmodernějšími materiály a technologiemi.
          </p>
        </InsulationText>
      </InsulationDescription>
    </InsulationContainer>
  );
}

export default Insulation;

export const InsulationContainer = styled.div`
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
`;

export const InsulationTitle = styled.h1`
  font-size: clamp(1.2rem, 5vw, 3rem);
  text-align: center;
  color: #000;
`;
export const InsulationTitleBg = styled.div`
  width: auto;
  padding: 8px 20px;
  background: rgba(255, 255, 255, 0.5);
`;

export const InsulationDescription = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  height: 100%;
  padding: 0 2rem;
`;

export const InsulationImage = styled.div`
  background-image: url(${InsImg});
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InsulationText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 300;
  line-height: 1.5;
  padding: 1rem 0;
  @media screen and (max-width: 1020px) {
    margin-left: 0;
    margin-top: 20px;
  }
`;
