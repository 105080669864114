import React from "react";
import styled from "styled-components";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { projectsData } from "../data/ProjectsData";

const ProjectSeven = () => {
  return (
    <ProjectSevenContainer>
      <ProjectSevenTitle>{projectsData[5].title}</ProjectSevenTitle>
      <ProjectSevenDesc>{projectsData[5].desc}</ProjectSevenDesc>
      <ProjectSevenImage items={projectsData[5].img} />
    </ProjectSevenContainer>
  );
};

export default ProjectSeven;

const ProjectSevenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
`;
const ProjectSevenTitle = styled.h1`
  font-size: clamp(1.2rem, 5vw, 3rem);
  text-align: center;
  margin-bottom: 5rem;
`;
const ProjectSevenDesc = styled.p`
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 300;
  padding: 0 2rem;
  padding-bottom: 2rem;
`;
const ProjectSevenImage = styled(ImageGallery)``;
