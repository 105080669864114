import React from "react";
import styled from "styled-components/macro";
import RenImg from "../assets/images/renovation.jpg";

function Renovations() {
  return (
    <RenovationsContainer>
      <RenovationsImage>
        <RenovationsTitleBg>
          <RenovationsTitle>Rekonstrukce bytů a domů</RenovationsTitle>
          <RenovationsSubtitle>
            Vtiskněte s námi novou tvář i vašemu starému domovu
          </RenovationsSubtitle>
        </RenovationsTitleBg>
      </RenovationsImage>
      <RenovationsDescription>
        <RenovationsText>
          <p>
            Provádíme rekonstrukce bytů, bytových jader a rodinných domů. Rozsah
            i postup při rekonstrukcích a přestavbách se vždy odvíjí od
            konkrétních požadavků a představ investora. <br /> Jsme schopni
            odborně realizovat kompletní rekonstrukce i přestavby od prvotního
            návrhu až po kolaudaci.
          </p>
          <br />
          <strong>
            Nemáte ještě zpracované projektové podkady? Pak budeme postupovat
            takto:
          </strong>
          <br />
          <ul>
            <li>
            ✓ Společná návštěva v místě rekonstruovaného nebo přestavovaného
              objektu, při které se zjistí stav objektu i rámcová představa
              budoucího užívání
            </li>
            <li>
            ✓ Zaměření a zpracování výkresové dokumentace stávajícího stavu (v
              případě potřeby kontrola stavby statikem)
            </li>
            <li>
            ✓ Schůzka, na které se předloží návrhy a společně dohodne vzhled a
              řešení výsledného provedení objektu
            </li>
            <li>
            ✓ Zpracování projektu rekonstrukce nebo přestavby. Při zpracování je
              projekt průběžně konzultován s investorem
            </li>
            <li>✓ Předložení cenové nabídky na provedení rekonstrukce</li>
            <li>
            ✓ Zajištění požadovaných povolení k realizaci rekonstrukce –
              obstaravatelská činnost
            </li>
            <li>
            ✓ Realizace kompletního provedení rekonstrukce nebo pouze
              požadovaného rozsahu (pokud si investor přeje některé části nebo
              dodávky zajišťovat sám)
            </li>
            <li>
            ✓ V průběhu rekonstrukce probíhají pravidelné kontrolní a
              koordinační schůzky s investorem nebo jeho technickým dozorem,
              pořizuje se fotodokumentace, předávají se formou zápisu do
              stavebního deníku stavební konstrukce, které jsou následně
              zakryty, kontroluje se způsob a kvalita provedení a časový
              harmonogram jednotlivých etap rekonstrukce atd. – obdobně, jako
              při realizaci novostavby
            </li>
            <li>
            ✓ Závěrečná kontrola, proškolení v obsluze, předání podkladů,
              předání díla a kolaudace objektu
            </li>
          </ul>
          <br />
          <strong>
            U většiny rekonstrukcí starších objektů bývá obvykle postup
            jednotlivých činností obdobný. Více informací Vám rádi poskytneme
            osobně nebo po telefonu.
          </strong>
        </RenovationsText>
      </RenovationsDescription>
    </RenovationsContainer>
  );
}

export default Renovations;

export const RenovationsContainer = styled.div`
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
`;

export const RenovationsTitle = styled.h1`
  font-size: clamp(1.2rem, 5vw, 3rem);
  text-align: center;
  margin-bottom: 1rem;
  color: #000;
`;

export const RenovationsSubtitle = styled.h2`
  text-align: center;
  font-style: italic;
  font-weight: 300;
`;

export const RenovationsTitleBg = styled.div`
  width: auto;
  padding: 8px 20px;
  background: rgba(255, 255, 255, 0.5);
`;

export const RenovationsDescription = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  height: 100%;
  padding: 0 2rem;
`;

export const RenovationsImage = styled.div`
  background-image: url(${RenImg});
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RenovationsText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 300;
  line-height: 1.5;
  padding: 1rem 0;
  @media screen and (max-width: 1020px) {
    margin-left: 0;
    margin-top: 20px;
  }
  li{
    list-style-type: none;
  }
`;
