import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Renovations from "../components/Renovations";
import Sidebar from "../components/Sidebar";
import Slider from "../components/Slider";

function RenovationsPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} hideLinkS />
      <Header toggle={toggle} hideLinkS />
      <Renovations />
      <Slider category="Rekonstrukce" title="Naše projekty rekonstrukce" />
      <Footer hideLinkS />
    </>
  );
}

export default RenovationsPage;
