import React, { useState } from "react";
import AboutUs from "../components/AboutUs";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Stats from "../components/Stats";
import Header from "../components/Header";
import Services from "../components/Services";
import Sidebar from "../components/Sidebar";
import Banner from "../components/Banner";

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Header toggle={toggle} white/>
      <Hero />
      {/* <Banner/> */}
      <Services />
      <Stats />
      <AboutUs />
      <Footer/>
    </>
  );
}

export default Home;
