import React, { useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

function ContactPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} hideLinkS />
      <Header toggle={toggle} hideLinkS />
      <Contact />
      <Footer hideLinkS/>
    </>
  );
}

export default ContactPage;
